<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card class="cardClassDetailForm" title="상세">
            <template slot="card-button">
              <q-btn-group outline >
                <q-chip
                  outline square
                  icon="update_disabled"
                  v-if="data.companionFlag==='Y'"
                  color="red"
                  text-color="white">
                  접수반려
                </q-chip>
                <!-- [S]결재관련 버튼 -->
                <!-- sysApprovalRequestId: 상세조회시 관련 결재요청일련번호 확인 -->
                <!-- popupParam.isApprContent: 결재요청/결재처리 팝업의 component에 해당 페이지 호출시 true -->
                <!-- approvalStatusCd: 결재요청건에 대한 상태코드 -->
                <!-- popupParam.approvalDocType: TODO(결재할문서에서 상세페이지호출시) -->
                <c-appr-btn 
                  ref="appr-btn"
                  name="equipment-daily-appr-btn"
                  :editable="editable"
                  :approvalInfo="approvalInfo"
                  @beforeApprAction="saveDataAppr"
                  @callbackApprAction="approvalCallback"
                  @requestAfterAction="getDetail"
                />
                <c-btn v-if="!popupParam.woRequestId && editable" label="기존 정비요청 불러오기" icon="upload_file" @btnClicked="copyData" />
                <c-btn v-if="popupParam.woRequestId &&!removeDisabled" label="삭제" icon="delete_forever" @btnClicked="removeData" />
                <c-btn
                  v-if="editable &&!disabled"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-equip
                  :required="true"
                  :editable="editable&&!disabled"
                  label="설비명"
                  name="equipmentCd"
                  v-model="data.equipmentCd"
                  @dataChange="equipDataChange">
                </c-equip>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-location 
                  type="edit" 
                  :plantCd="data.plantCd" 
                  :editable="editable&&!disabled" 
                  label="장소" 
                  :data="data"
                  etcInput="locationEtc"
                  name="locationCd" 
                  v-model="data.locationCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-select
                  :required="true"
                  :editable="editable&&!disabled"
                  codeGroupCd="WO_WORK_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="woWorkTypeCd"
                  label="작업분류"
                  v-model="data.woWorkTypeCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :required="true"
                  :editable="editable&&!disabled"
                  label="작업명"
                  name="woRequestName"
                  v-model="data.woRequestName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  :required="true"
                  :editable="editable&&!disabled"
                  default="today"
                  label="요청일"
                  name="woRequestDt"
                  v-model="data.woRequestDt"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-dept type="edit" :editable="editable&&!disabled" label="정비요청 부서" :required="true" name="deptCd" v-model="data.deptCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-field
                  :required="true"
                  :editable="editable&&!disabled"
                  :data="data"
                  type="user"
                  label="요청인"
                  name="userId"
                  v-model="data.userId" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant
                  :required="true"
                  :editable="editable&&!disabled"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
                <c-checkbox
                  :editable="editable&&!disabled"
                  :isFlag="true"
                  label="긴급작업"
                  name="emergencyFlag"
                  v-model="data.emergencyFlag"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable&&!disabled&&data.emergencyFlag=='Y'"
                  label="긴급작업 사유"
                  name="emergencyReason"
                  v-model="data.emergencyReason">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-if="data.woWorkReqStepCd==='WWRSC00009'">
                <c-text
                  v-if="data.woWorkReqStepCd==='WWRSC00009'"
                  :editable="editable"
                  :disabled="true"
                  label="반려사유"
                  name="refusalReason"
                  v-model="data.refusalReason">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                <c-textarea
                  :editable="editable&&!disabled"
                  :rows="7"
                  label="정비요청 세부사항"
                  name="remark"
                  v-model="data.remark">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-table
                  ref="grid"
                  title="정비오더 작업부서"
                  :columns="grid.columns"
                  :data="data.deptList"
                  :gridHeight="grid.height"
                  :editable="editable&&!disabled"
                  :hideBottom="true"
                  :isExcelDown="false"
                  :filtering="false"
                  :isFullScreen="false"
                  :columnSetting="false"
                  selection="single"
                  rowKey="deptCd"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="추가" icon="add" @btnClicked="addRow" v-if="editable&&!disabled&&data.deptList.length < 1" />
                      <c-btn label="삭제" icon="remove" @btnClicked="removeRow" v-if="editable&&!disabled" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top:25px !important;">
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable&&!disabled"
            label="첨부파일">
          </c-upload>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-order-request-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        woRequestId: '',
        plantCd: null,
        minEquipmentCheckId: '',
        dailyCheckId: '',
        equipmentCd: '',
        woRequestName: '',
        remark: '',
        woWorkTypeCd: '',
        costCenterCd: '',
        locationCd: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      popupOptions: {
        isFull: false,
        width: '50%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'WO_WORK_REQUEST',
        taskKey: '',
      },
      editable: true,
      isApproval: false,
      saveUrl: transactionConfig.wod.workreq.insert.url,
      mappingType: 'POST',
      detailUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      deptDeleteUrl: '',
      deptInsertUrl: '',
      deptListUrl: '',
      isSave: false,
      isdelete: false,
      data: {
        plantCd: null,  // 사업장코드
        woRequestId: '',  // 정비요청 일련번호
        woRequestName: '',  // 작업명
        deptCd: '',  // 발행 부서
        userId: '',  // 발행인
        woRequestDt: '',  // 발행일
        woWorkTypeCd: null,  // 작업 유형코드
        equipmentCd: '',  // 대상 설비
        locationCd: '',  // 기능위치
        locationEtc: '',  // 기능위치
        remark: '',  // 정비요청 세부사항
        woWorkReqStepCd: 'WWRSC00001',  // 정비요청 상태(작성중)
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        emergencyFlag: '',
        emergencyReason: '',
        refusalReason: '',
        companionFlag: 'N',
        deptList: [],
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
        height: '170px',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    approvalInfo() {
      return {
        sysApprovalRequestId: this.data.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.data.approvalStatusCd, 
        apprEditable: this.data.woWorkReqStepCd && this.data.woWorkReqStepCd !== 'WWRSC00003' && this.editable&&this.popupParam.woRequestId, // 결재버튼 활성화 기준
        param: this.data, // 결재 param
        approvalUrl: this.updateUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000020', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          woRequestId: this.data.woRequestId
        },
        approvalRequestName: '정비요청 ['+this.data.woRequestName+']', // 결재요청명 (문서 title)
        approvalConnId: this.popupParam.woRequestId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    // [E] 결재관련 버튼 컨트롤
    disabled() {
      return this.data.woWorkReqStepCd !== 'WWRSC00001' && this.data.woWorkReqStepCd !== 'WWRSC00009'
    },
    removeDisabled() {
      return this.data.woWorkReqStepCd !== 'WWRSC00001'
    }
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.wod.workreq.get.url;
      this.insertUrl = transactionConfig.wod.workreq.insert.url;
      this.updateUrl = transactionConfig.wod.workreq.update.url;
      this.deleteUrl = transactionConfig.wod.workreq.delete.url;
      this.deptListUrl = selectConfig.wod.workreq.depts.url;
      this.deptInsertUrl = transactionConfig.wod.workreqdept.insert.url;
      this.deptDeleteUrl = transactionConfig.wod.workreqdept.delete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.woRequestId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {woRequestId: this.popupParam.woRequestId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          this.$set(this.attachInfo, 'taskKey', this.popupParam.woRequestId)
        },);
      } else {
        this.data.plantCd = this.popupParam.plantCd;
        this.data.equipmentCd = this.popupParam.equipmentCd;
        this.data.locationCd = this.popupParam.locationCd;
        this.data.costCenterCd = this.popupParam.costCenterCd;
        this.data.woWorkTypeCd = this.popupParam.woWorkTypeCd;
        this.data.woRequestName = this.popupParam.woRequestName;
        this.data.remark = this.popupParam.remark;
        this.data.woWorkReqStepCd = 'WWRSC00001';
        this.data.userId = this.$store.getters.user.userId;
        if (this.popupParam.woRequestDt) {
          this.data.woRequestDt = this.popupParam.woRequestDt
        } else {
          this.data.woRequestDt = this.$comm.getToday();
        }
      }
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    saveDataAppr() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.data.deptList.length == 0) {
            window.getApp.$emit('ALERT', {
              title: '안내 ' /* 안내 */,
              message: '정비오더 작업부서를 지정하세요.' /* 저장되었습니다. */,
              type: 'warning', // success / info / warning / error
            });
          } else {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.data.regUserId = this.$store.getters.user.userId;
                this.data.chgUserId = this.$store.getters.user.userId;
                
                let saveDataDepts = this.data.deptList.filter( x => {
                  return x.editFlag == 'C'
                });
                this.data.deptList = saveDataDepts;

                let promises = [
                  {
                    func: this.approvalValue
                  },
                ];
                this.$comm.orderedPromise(promises);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    saveData() {
      if (this.popupParam.woRequestId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
        this.data.dailyCheckId = this.popupParam.dailyCheckId;
        this.data.minEquipmentCheckId = this.popupParam.minEquipmentCheckId;
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.data.deptList.length == 0) {
            window.getApp.$emit('ALERT', {
              title: '안내 ' /* 안내 */,
              message: '정비오더 작업부서를 지정하세요.' /* 저장되었습니다. */,
              type: 'warning', // success / info / warning / error
            });
          } else {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.data.regUserId = this.$store.getters.user.userId;
                this.data.chgUserId = this.$store.getters.user.userId;

                let saveDataDepts = this.data.deptList.filter( x => {
                  return x.editFlag == 'C'
                });
                this.data.deptList = saveDataDepts;
                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '정비요청을 삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.woRequestId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.woRequestId = result.data.woRequestId
      this.$set(this.attachInfo, 'taskKey', this.popupParam.woRequestId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.getDetail();
    },
    addRow() {
      this.popupOptions.title = '부서 검색'; // 부서 검색
      this.popupOptions.param = {
        selection: 'single',
        plantCd: this.data.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/dept/deptMultiPop.vue'}`);
      this.popupOptions.width = '40%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeDeptPopup;
    },
    closeDeptPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (this.data.deptList.length == 1) {
          window.getApp.$emit('ALERT', {
            title: '안내 ' /* 안내 */,
            message: '정비오더 작업부서는 하나의 부서만 지정할 수 있습니다.' /* 저장되었습니다. */,
            type: 'warning', // success / info / warning / error
          });
        } else {
          this.$_.forEach(data, item => {
            if (this.$_.findIndex(this.data.deptList, { deptCd: item.deptCd }) === -1) {
              this.data.deptList.push({
                woRequestId: this.popupParam.woRequestId,  // 정비요청 일련번호
                deptCd: item.deptCd,  // 작업 부서
                deptName: item.deptName,  // 작업 부서
                plantName: item.plantName,  // 작업 부서
                editFlag: 'C',
                regUserId: this.$store.getters.user.userId,
              });
            }
          });
        }
      }
    },
    removeRow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deptDeleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.data.deptList = this.$_.reject(this.data.deptList, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    equipDataChange(data) {
      // 기능위치 변경
      if (data.locationCd !== null) {
        if (this.data.locationCd === null) {
          this.data.locationCd = data.locationCd
        } else {
          if (this.data.locationCd != data.locationCd) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '선택한 설비의 기능위치로 변경하시겠습니까?',
              type: 'warning', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.data.locationCd = data.locationCd
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        }
      }
      // 코스트센터 연결 추가
      if (this.data.costCenterCd === null) {
        this.data.costCenterCd = data.costCenterCd
      } else {
        if (this.data.costCenterCd != data.costCenterCd) {
          this.data.costCenterCd = data.costCenterCd
        }
      }
    },
    copyData() {
      this.popupOptions.title = '기존 정비요청 검색'; // 정비작업표준 검색
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.data.plantCd
      };
      this.popupOptions.target = () => import(`${'./workOrderRequestPopUp.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCopyPopup;
    },
    closeCopyPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.length === 1) {
        this.$http.url = this.detailUrl;
        this.$http.param = {woRequestId: data[0].woRequestId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.data.woRequestId = '';
          this.data.woWorkReqStepCd = 'WWRSC00001';  // 정비요청 상태(작성중)
          this.data.approvalStatusCd = '';
          this.data.woRequestDt = '';
          this.data.deptCd = this.$store.getters.user.deptCd;
          this.data.userId = this.$store.getters.user.userId;
          this.data.sysApprovalRequestId = '';
          this.data.deptList[0].woRequestId = '';
          this.data.deptList[0].editFlag = 'C';
          this.data.deptList[0].regUserId = this.$store.getters.user.userId;
        },);
      }
    },
  }
};
</script>